import { Link, StaticQuery, graphql } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import { Row, Col } from "react-bootstrap"

const Lauftext = () => (
  <StaticQuery
      query={graphql`
          query {
            wordpressAcfOptions {
              options {
                input
              }
            }
          }
      `}
      render={data => (
        <marquee>
            <h2 dangerouslySetInnerHTML={{ __html: data.wordpressAcfOptions.options.input }} />
        </marquee>)}
  />
)

const Header = ({ siteTitle }) => (
  <header className="mb-2 mt-3">
    <Row className="p-0">
      <Col sm="auto" className="pr-0">
        <h1>
          <Link
            to="/">
            {siteTitle}
          </Link>
        </h1>
      </Col>
      <Col sm="col" className="col pl-0 lauftext d-flex h-100">
        <Lauftext />
      </Col> 
    </Row>
  </header>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header


