import { StaticQuery, graphql} from "gatsby"
import React from "react"
import AniLink from "gatsby-plugin-transition-link/AniLink";




const Menu = () => (
    <StaticQuery
        query={graphql`
            query {
              wordpressWpApiMenusMenusItems(name: { eq: "main-menu" }) {
                items {
                  title
                  object_slug
                }
              }
            }
        `}
        render={data => (
            <div className="container-fluid border-top border-bottom">
              <nav>
                <ul className="d-flex space-between p-0 m-0">
                  {data.wordpressWpApiMenusMenusItems.items.map(item => (
                    <li key={item.object_slug} className="nav-item">
                      <AniLink  to={`/${item.object_slug}`} activeClassName="active" fade duration={0.5}>
                        {item.title}
                      </AniLink >
                    </li>
                  ))}
                </ul>
              </nav>
            </div>
        )}
    />
)

export default Menu